var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "rounded-lg pa-2 d-flex align-center",
      attrs: { flat: "", color: "buttonGrayLight" },
    },
    [
      _vm.loading
        ? _c("v-skeleton-loader", {
            staticClass: "nft-manager-details-loader buttonGrayLight py-2",
            attrs: { width: "100%", height: "100%", type: "list-item-avatar" },
          })
        : _vm._e(),
      !_vm.loading
        ? _c("div", { staticClass: "d-flex align-center full-width" }, [
            _c("img", {
              attrs: { height: "100", src: _vm.token.image, alt: "nft token" },
              on: { error: _vm.onImgErr },
            }),
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-center flex-column flex-md-row flex-lg-row flex-xl-row justify-space-between pa-3 full-width",
              },
              [
                _c("div", { staticClass: "nft-name pr-2" }, [
                  _vm.token
                    ? _c(
                        "span",
                        {
                          class: _vm.$vuetify.breakpoint.smAndDown
                            ? "mb-1"
                            : "",
                        },
                        [
                          _vm._v(
                            "#" + _vm._s(_vm.token.name || _vm.token.token_id)
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c("mew-button", {
                  attrs: {
                    "has-full-width": false,
                    "btn-style": "outline",
                    title: "Send",
                    "btn-size": "large",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onClick(_vm.token)
                    },
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }